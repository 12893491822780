<template>
    <v-row>
        <v-col cols="12" sm="12">
            <v-card>
                <v-card-text>
                    <v-toolbar>
                        <v-toolbar-title>Rental Application for ...</v-toolbar-title>
                    </v-toolbar>

                    <v-row>
                        <v-col cols="12" md="6" lg="3">
                            <v-card class="primary lighten-4 d-flex justify-center">
                                <v-avatar size="220">
                                    <v-icon class="text-h1">mdi-home-city</v-icon>
                                </v-avatar>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="12" lg="5">
                            <psi-detail-card
                                icon="mdi-home-city"
                                :heading-title="`Property`"
                                :items="propertyItems"
                                :itemNew="false"
                            ></psi-detail-card>
                        </v-col>
                    </v-row>
                    <v-row v-if="'profile' in applicant">
                        <v-col cols="4">
                            <psi-detail-card
                                icon="mdi-clipboard-account"
                                :heading-title="`Profile`"
                                :items="items"
                                :itemNew="false"
                                :heading-subtitle="$_.get(applicant,'name','')"
                            ></psi-detail-card>
                        </v-col>

                        <v-col cols="4">
                            <psi-detail-card
                                icon="mdi-clipboard-account"
                                :heading-title="`Address`"
                                :items="addressItems"
                                :itemNew="false"
                                :heading-subtitle="`Current`"
                            ></psi-detail-card>
                        </v-col>

                        <!-- <template v-for="(key,index) in keys">
                        <v-col cols="12" md="6" lg="4" :key="index">
                            <psi-detail-card
                                icon="mdi-clipboard-account"
                                :heading-title="applicant.name"
                                :items="upload.items"
                                :itemNew="upload.itemNew"
                                :heading-subtitle="`Created ${upload.created_at}`"
                            ></psi-detail-card>
                        </v-col>
                        </template>-->
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "application-upload-review",
    components: {},
    props: {},
    data() {
        return {
            petItems: [{}],
            propertyItems: [
                {
                    icon: "mdi-map-marker",
                    label: "Address",
                    type: "text",
                    field: "address",
                    color: "",
                    copy: false,
                    text: "33111 N 25th Dr, Phoenix AZ 85085",
                    order: 1,
                },
                {
                    icon: "mdi-clipboard-check",
                    label: "Submitted",
                    type: "text",
                    field: "submitted",
                    color: "",
                    copy: false,
                    text: "Via website on 6/28",
                    order: 1,
                },
                {
                    icon: "mdi-cash-usd",
                    label: "Market Rent",
                    type: "text",
                    field: "rent_amount",
                    color: "",
                    copy: false,
                    text: "$2425.00",
                    order: 1,
                },
                {
                    icon: "mdi-calendar-arrow-right",
                    label: "Desired Move In",
                    type: "text",
                    field: "movein_date",
                    color: "",
                    copy: false,
                    text: "09/15/2020",
                    order: 1,
                },
            ],
            addressItems: [
                {
                    icon: "mdi-map-marker",
                    label: "Street",
                    type: "text",
                    field: "address:street",
                    color: "",
                    copy: false,
                    text: "33111 N 25th Dr",
                    order: 1,
                },
                {
                    icon: "mdi-door-closed",
                    label: "Unit",
                    type: "text",
                    field: "address:unit",
                    color: "",
                    copy: false,
                    text: "1B",
                    order: 1,
                },
                {
                    icon: "mdi-city",
                    label: "City",
                    type: "text",
                    field: "address:city",
                    color: "",
                    copy: false,
                    text: "Phoenix",
                    order: 1,
                },
                {
                    icon: "mdi-flag",
                    label: "State",
                    type: "text",
                    field: "address:state",
                    color: "",
                    copy: false,
                    text: "Arizona",
                    order: 1,
                },
                {
                    icon: "mdi-mailbox-open",
                    label: "Postal Code",
                    type: "text",
                    field: "zip",
                    color: "",
                    copy: false,
                    text: "85085",
                    order: 1,
                },

                {
                    heading: "Landlord",
                    order: 1,
                },
                {
                    icon: "mdi-account",
                    label: "Name",
                    type: "text",
                    field: "landlord:name",
                    color: "",
                    copy: false,
                    text: "Ron",
                    order: 1,
                },
                {
                    icon: "mdi-email",
                    label: "Email",
                    type: "text",
                    field: "landlord:email",
                    color: "",
                    copy: false,
                    text: "joe@test.com",
                    order: 1,
                },
                {
                    icon: "mdi-cellphone",
                    label: "Phone",
                    type: "text",
                    field: "landlord:phone",
                    color: "",
                    copy: false,
                    text: "joe@test.com",
                    order: 1,
                },
                {
                    icon: "mdi-fax",
                    label: "Fax",
                    type: "text",
                    field: "landlord:fax",
                    color: "",
                    copy: false,
                    text: "joe@test.com",
                    order: 1,
                },
                {
                    icon: "mdi-account-switch-outline",
                    label: "Relationship",
                    type: "text",
                    field: "landlord:relationship",
                    color: "",
                    copy: false,
                    text: "Friend",
                    order: 1,
                },
                {
                    heading: "Other",
                    order: 1,
                },
                {
                    icon: "d",
                    label: "Move In Date",
                    type: "text",
                    field: "meta:movein_date",
                    color: "",
                    copy: false,
                    text: "01/01/2000",
                    order: 1,
                },
                {
                    icon: "mdi-calendar-arrow-left",
                    label: "Move Out Date",
                    type: "text",
                    field: "meta:moveout_date",
                    color: "",
                    copy: false,
                    text: "01/01/2010",
                    order: 1,
                },
                {
                    icon: "mdi-calendar-text",
                    label: "Reason for moving",
                    type: "text",
                    field: "meta:reason_for_moving",
                    color: "",
                    copy: false,
                    text: "Lorum ippsum lorum ipsum lorum ipsum lorum lorum",
                    order: 1,
                },
                {
                    icon: "mdi-calendar-text",
                    label: "Reason for moving",
                    type: "text",
                    field: "meta:reason_for_moving",
                    color: "",
                    copy: false,
                    text: "Lorum ippsum lorum ipsum lorum ipsum lorum lorum",
                    order: 1,
                },
                {
                    icon: "mdi-cash-usd",
                    label: "Monthly Rent",
                    type: "text",
                    field: "meta:rent",
                    color: "",
                    copy: false,
                    text: "$1500.00",
                    order: 1,
                },
            ],
            items: [
                {
                    icon: "mdi-account",
                    label: "First Name",
                    type: "text",
                    field: "first_name",
                    color: "success",
                    copy: false,
                    text: "Ron",
                    order: 1,
                },
                {
                    icon: "mdi-account",
                    label: "Middle Name",
                    type: "text",
                    field: "middle_name",
                    color: "",
                    copy: false,
                    text: "William",
                    order: 2,
                },
                {
                    icon: "mdi-account",
                    label: "Last Name",
                    type: "text",
                    field: "last_name",
                    color: "",
                    copy: false,
                    text: "Fuller",
                    order: 3,
                },
                {
                    icon: "mdi-calendar",
                    label: "Birthdate",
                    type: "text",
                    field: "birthdate",
                    color: "",
                    copy: false,
                    text: "01/18/1966",
                    order: 4,
                },
                {
                    icon: "mdi-account-lock",
                    label: "SSN",
                    type: "text",
                    field: "ssn",
                    color: "",
                    copy: false,
                    text: "111-11-1111",
                    order: 5,
                },
                {
                    icon: "mdi-account-lock",
                    label: "ITIN",
                    type: "text",
                    field: "itin",
                    color: "",
                    copy: false,
                    text: "111-11-1111",
                    order: 6,
                },
                {
                    icon: "mdi-lock-open",
                    label: "Freeze Code",
                    type: "text",
                    field: "freeze_code",
                    color: "",
                    copy: false,
                    text: "GR9072980474G66G6079717NX9I",
                    order: 6,
                },
                {
                    icon: "mdi-email",
                    label: "Email",
                    type: "text",
                    field: "email",
                    color: "",
                    copy: false,
                    text: "joe@test.com",
                    order: 7,
                },
                {
                    icon: "mdi-cellphone",
                    label: "Mobile Phone",
                    type: "text",
                    field: "mobile_phone",
                    color: "",
                    copy: false,
                    text: "(503) 111-1111",
                    order: 8,
                },
                {
                    icon: "mdi-phone-classic",
                    label: "Home Phone",
                    type: "text",
                    field: "home_phone",
                    color: "",
                    copy: false,
                    text: "(503) 222-2222",
                    order: 8,
                },
            ],

            keys: [
                "profile",
                "dependents",
                "addresses",
                "employers",
                "vehicles",
                "pets",
                "emergencyContact",
            ],
        };
    },
    computed: {
        ...mapGetters("Application", ["application"]),
        applicant: {
            immediate: true,
            get() {
                return this.application ? this.application.applicants[0] : {};
            },
        },
        // Format the uploaded data for a grid detailed row display
    },
    watch: {},
    created() {},
    mounted() {
        this.getApplication();
    },
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Application", [
            "getApplication",
            "getApplicant",
            "addApplication",
        ]),
    },
};
</script>

<style scoped>
.btn-views {
    opacity: 0.5;
}
.btn-views-active {
    opacity: 1;
}
</style>