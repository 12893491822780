var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-toolbar",
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Rental Application for ...")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", lg: "3" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "primary lighten-4 d-flex justify-center"
                            },
                            [
                              _c(
                                "v-avatar",
                                { attrs: { size: "220" } },
                                [
                                  _c("v-icon", { staticClass: "text-h1" }, [
                                    _vm._v("mdi-home-city")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12", lg: "5" } },
                        [
                          _c("psi-detail-card", {
                            attrs: {
                              icon: "mdi-home-city",
                              "heading-title": "Property",
                              items: _vm.propertyItems,
                              itemNew: false
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  "profile" in _vm.applicant
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("psi-detail-card", {
                                attrs: {
                                  icon: "mdi-clipboard-account",
                                  "heading-title": "Profile",
                                  items: _vm.items,
                                  itemNew: false,
                                  "heading-subtitle": _vm.$_.get(
                                    _vm.applicant,
                                    "name",
                                    ""
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("psi-detail-card", {
                                attrs: {
                                  icon: "mdi-clipboard-account",
                                  "heading-title": "Address",
                                  items: _vm.addressItems,
                                  itemNew: false,
                                  "heading-subtitle": "Current"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }